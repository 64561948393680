<template>
  <div>
    <q-select
      v-model="value"
      :options="optionsFilter"
      :label="label"
      :dense="dense"
      :multiple="multiple"
      :option-label="optionLabel"
      :option-value="optionValue"
      :outlined="outlined"
      :loading="loading"
      use-input
      @filter="filterFn"
      input-debounce="2"
      :use-chips="useChips"
      :emit-value="emitValue"
      :rules="rules"
      ref="selectRequest"
    >
      <template v-if="value && displayCloseAll" v-slot:append>
        <q-icon name="mdi-close" @click.stop="value = null" class="cursor-pointer" />
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  name: 'SelectRequest',
  props: {
    vModel: [Object, Array, String, Number],
    label: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    displayCloseAll: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    useChips: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      default: null,
      require: true
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    emitValue: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    formatLabelAffiliate: {
      type: Boolean,
      default: false
    },
    formatLabelAdvertiser: {
      type: Boolean,
      default: false
    },
    formatLabelCampaign: {
      type: Boolean,
      default: false
    },
    formatLabelAffiliateCampaign: {
      type: Boolean,
      default: false
    },
    formatLabelChannel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: null,
      loading: false,
      options: [],
      optionsFilter: []
    };
  },
  methods: {
    async getRequest() {
      this.loading = true;
      if (!this.endpoint) throw 'endpoint not null';
      try {
        const { data, status } = await this.$http.get(this.endpoint);
        if (status === 200) {
          this.options = data;

          if (this.formatLabelAffiliate) {
            this.options.forEach(function (part, index, theArray) {
              theArray[index].name = `${theArray[index].name} (ID: ${theArray[index].id})`;
            });
          }

          if (this.formatLabelAdvertiser) {
            this.options.forEach(function (part, index, theArray) {
              theArray[index].trademark = `${theArray[index].trademark} (ID: ${theArray[index].id})`;
            });
          }

          if (this.formatLabelCampaign) {
            this.options.forEach(function (part, index, theArray) {
              theArray[index].name = `${theArray[index].name} (ID: ${theArray[index].id})`;
            });
          }
          if (this.formatLabelAffiliateCampaign) {
            this.options.forEach(function (part, index, theArray) {
              theArray[index].campaign_name = `${theArray[index].campaign_name} (ID: ${theArray[index].campaign_id})`;
            });
          }
          if (this.formatLabelChannel) {
            this.options.forEach(function (part, index, theArray) {
              theArray[index].name = `${theArray[index].name} (ID: ${theArray[index].id})`;
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.loading = false;
      }
    },

    filterFn(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.optionsFilter = this.options;
        this.optionsFilter = this.options.filter((v) => v[this.optionLabel].toLocaleLowerCase().indexOf(needle) > -1);
      });
      return;
    },

    emitData() {
      this.$emit('update', this.value);
    },

    clearValue() {
      if (!this.vModel || this.vModel == null || this.vModel == undefined) this.value = null;
      else {
        // this.value = this.vModel;
        this.value = this.options.find((item) => item.id == this.vModel);
        // console.log('this.options', this.options);
        // console.log('value', this.value);
        // console.log('vModel', this.vModel);
      }
    }
  },

  watch: {
    value: 'emitData',
    vModel() {
      if (this.vModel == null || this.vModel.length == 0) {
        this.$refs.selectRequest.value = null;
      }
      //   this.$emit("update", { name: null, id: null });
    },
    endpoint() {
      this.getRequest();
    }
    // vModel: "clearValue",
  },

  created() {
    this.getRequest();
    // if (this.vModel ) this.value = this.vModel
  },

  mounted() {
    // console.log("vModel", this.vModel);
    if (this.vModel) this.value = this.vModel;
  }
};
</script>

<style></style>
