<template>
  <div>
    <q-banner class="bg-cyan-2 text-info q-my-md" rounded>
      <q-item>
        <q-item-section top avatar>
          <q-icon name="mdi-information-outline" size="lg" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption class="text-black">
            <strong>Atenção: </strong>ao incluir um afiliado a um grupo, você conta que este possui informações
            financeiras registradas na plataforma. Caso contrário, a ação <strong>não surtirá mudanças </strong>seu
            valor mínimo de pagamento. Em caso de dúvidas, o afiliado pode consultar suas informações acessando através
            de sua conta e navegando em <strong>Minha Conta - Informações de Pagamento.</strong>
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-banner>
    <q-card flat bordered class="q-my-md">
      <div class="q-pa-sm row justify-around">
        <q-card
          v-for="group in customPayments"
          :key="group.id"
          flat
          bordered
          class="col-6 q-ma-sm"
          style="height: 350px; width: 550px"
        >
          <q-toolbar class="bg-primary text-white">
            <div class="text-white text-h5">R$ {{ group.value }}</div>

            <q-space />

            <q-btn icon="mdi-trash-can-outline" flat dense round @click="removeGroup(group.id)" />
          </q-toolbar>
          <q-card-section>
            <select-request
              outlined
              label="Adicionar um afiliado..."
              endpoint="/redirect?app=AFFILIATE&path=/api/get/affiliate"
              option-label="name"
              option-value="id"
              formatLabelAffiliate
              clearable
              @update="(value) => (value === null ? null : addChannelInGroup(value.id, group.id))"
            />
          </q-card-section>
          <q-card-section class="scroll">
            <q-list bordered class="scroll" style="max-height: 200px">
              <q-item v-for="item in group.affiliateInGroup" :key="item.id">
                <q-item-section>
                  <q-item-label>{{ item.affiliate.name }} (ID: {{ item.affiliate_id }})</q-item-label>
                </q-item-section>
                <q-item-section avatar>
                  <q-btn icon="mdi-close" flat dense round @click="removeChannelInGroup(item.affiliate_id)" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>
      </div>
    </q-card>
  </div>
</template>

<script>
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import FinancialMixin from '../../mixins/FinancialMixin';
import FinanceService from '../../services/FinanceService';

export default {
  components: { SelectRequest },
  mixins: [FinancialMixin, FinanceService],
  created() {
    this.getCustomPayments();
    console.log('customInvoiceGroups', this.customInvoiceGroups);
  },
  data() {
    return {
      affiliate: null,
      customInvoiceGroups: []
    };
  },
  methods: {
    removeCampaign(index) {
      this.campaigns.splice(index, 1);
    },
    removeSite(index) {
      this.sites.splice(index, 1);
    },

    async getCustomPaymentGroups() {
      this.onLoading(true);
      try {
        const { data, status } = await this.listCustomPaymentGroups();
        if (status === 200) {
          this.customInvoiceGroups = data;

          this.customInvoiceGroups.forEach((item) => {
            item.affiliateInGroup = this.collectAffiliateInGroup(item.id);
          });

          console.log('this.customInvoiceGroups ', this.customInvoiceGroups);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async addChannelInGroup(channelId, groupId) {
      this.onLoading(true);

      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: `finance`,
          path: `/api/custom_payment_groups_affiliate/`,
          params: {
            data: {
              affiliate_id: channelId,
              custom_payment_groups_id: groupId
            }
          }
        });
        if (status === 200) {
          this.getCustomPayments();

          this.successNotify(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async removeGroup(groupId) {
      this.showDialog(
        'Remover Grupo',
        'Tem certeza que deseja apagar esse grupo?',
        () => {
          this.onOk(groupId);
        },
        () => {
          this.onCancel;
        }
      );
    },

    async onOk(groupId) {
      this.onLoading(true);

      try {
        const { data, status } = await this.$http.delete(
          `v2/redirect?app=finance&path=/api/custom_payment_groups/${groupId}`
        );
        if (status === 200) {
          this.getCustomPayments();

          this.successNotify(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    onCancel() {
      this.errorNotify('Ação cancelad');
    },

    async removeChannelInGroup(channelId) {
      this.onLoading(true);

      try {
        const { data, status } = await this.$http.delete(
          `v2/redirect?app=finance&path=/api/custom_payment_groups_affiliate/by_affiliate/${channelId}`
        );
        if (status === 200) {
          this.getCustomPayments();

          this.successNotify(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async collectAffiliateInGroup(groupId) {
      try {
        const { data, status } = await this.getCustomPaymentGroupAffiliateByGroup(groupId);
        if (status === 200) {
          return data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    }
  }
};
</script>
