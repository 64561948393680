<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Pagamentos Diferenciados</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Pagamentos Diferenciados" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-custom-payments></header-custom-payments>
      <list-custom-payments></list-custom-payments>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderCustomPayments from '../../components/financial/HeaderCustomPayments.vue';
import ListCustomPayments from '../../components/financial/ListCustomPayments.vue';

export default {
  name: 'CustomPayments',
  components: { HeaderCustomPayments, ListCustomPayments }
};
</script>
