<template>
  <div>
    <q-btn
      unelevated
      color="primary"
      class="q-px-lg"
      icon="mdi-plus"
      label="Novo grupo de pagamento"
      no-caps
      @click="prompt = true"
    />
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Valor de pagamento do grupo</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input type="number" dense v-model.number="value" autofocus />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Cancelar" v-close-popup />
          <q-btn flat label="Criar grupo" @click="createCustomGroup" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  mixins: [FinancialMixin],
  data() {
    return {
      prompt: false,
      value: null
    };
  },
  methods: {
    async createCustomGroup() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: `finance`,
          path: `/api/custom_payment_groups/`,
          params: {
            data: {
              value: this.value
            }
          }
        });
        if (status === 200) {
          this.value = null;
          this.prompt = false;

          await this.getCustomPayments();

          this.successNotify(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
           this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
         else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>
