import { mapActions, mapGetters } from 'vuex';
import FinanceService from '../services/FinanceService';

export default {
  mixins: [FinanceService],
  methods: {
    ...mapActions('Financial', [
      'ActionSetDialogFinancial',
      'ActionSetDialogFinancialPI',
      'ActionSetAdvertiserInvoiceUpdate',
      'ActionSetDialogEditPi',
      'ActionSetPis',
      'ActionSetFilterPis',
      'ActionSetBillings',
      'ActionSetFilterBillings',
      'ActionSetAdvertiserInvoices',
      'ActionSetDialogCreateAdvertiserInvoice',
      'ActionSetAffiliateBillings',
      //'ActionSetFilterAffiliateBillings',

      'ActionSetSchedulePayments',
      'ActionSetInvoicesPayments',
      'ActionSetBillingsByAffiliate',
      'ActionSetFilterSchedulePayments',
      'ActionSetFilterInvoicesPayments',
      'ActionSetFilterBillingsByAffiliate',
      'ActionSetCustomPayments',
      'ActionSetFilterCustomPayments',
      'ActionSetFilterInvoicesPaymentsStep',
      'ActionSetInvoicesPaymentsStep'
    ]),

    async getPIs(page, perPage) {
      let query;

      if (!this.filterPis) query = 'sort=id%7Cdesc';
      else query = `${this.filterPis}%26sort=id%7Cdesc%26`;

      if (!page) query += 'page=1';
      else query += page;
      if (!perPage) query += '%26per_page=12';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=FINANCE&path=/api/pi/v2/search?' + query);
        if (status === 200) {
          this.ActionSetPis(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        this.errorNotify(error.response.data.data.msg);
      } finally {
        this.ActionSetFilterSchedulePayments();
        this.onLoading(false);
      }
    },

    async getBillings(params) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.post('/redirect?app=FINANCE&path=/api/invoice/search', params);
        if (status === 200) {
          console.log('ActionSetBillings ', data);
          this.ActionSetBillings(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.ActionSetFilterSchedulePayments();
        this.onLoading(false);
      }
    },

    async getAffiliateBillings(params) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(
          '/redirect?app=FINANCE&path=/api/commission-affiliate/available/' + params
        );
        if (status === 200) this.ActionSetAffiliateBillings(data);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getSchedulePayments(page, perPage) {
      let query = this.filterReports ? `${this.filterReports}%26` : '';

      query += page ? page : '%26page=1';
      query += perPage ? perPage : `%26perPage=${this.pagination?.rowsPerPage || 10}`;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=FINANCE&path=/api/payment_requests/v2?' + query);
        if (status === 200) {
          // let filteredData = data.data.filter((el) => {
          //     return el.commission.length > 0
          // });

          this.ActionSetSchedulePayments(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.ActionSetFilterSchedulePayments();
        this.onLoading(false);
      }
    },

    async getAffiliateInvoicesPayments(page, perPage) {
      let query;

      if (!this.filterReports) query = '';
      else query = `${this.filterReports}%26`;

      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26per_page=10';
      else query += perPage;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=FINANCE&path=/api/invoices_payments?' + query);
        if (status === 200) {
          this.ActionSetInvoicesPayments(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getInvoicesPayments(advertiserId, campaignId, startMonth, endMonth, startYear, endYear) {
      let searchParams = {
        advertizer_id: advertiserId,
        campaign_id: campaignId,
        initial_month: startMonth,
        final_month: endMonth,
        initial_year: startYear,
        final_year: endYear
      };

      this.onLoading(true);
      try {
        const { data, status } = await this.searchInvoice(searchParams);

        if (status === 200) {
          this.ActionSetInvoicesPayments(data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getInvoicesPaymentsStep(page, perPage) {
      this.onLoading(true);
      console.log(this.filterInvoicesPaymentsStep);
      let query;
      if (!this.filterInvoicesPaymentsStep) query = 'order_by=trademark%7Casc';
      else query = `${this.filterInvoicesPaymentsStep}%26order_by=trademark%7Casc`;
      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26perPage=10';
      else query += perPage;
      try {
        const { data, status } = await this.listInvoicesPaymentsStep(query);
        if (status === 200) {
          this.ActionSetInvoicesPaymentsStep(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getInvoicesPaymentsAffiliate(page, perPage) {
      this.onLoading(true);
      console.log(this.filterInvoicesPaymentsStep);
      let query;
      if (!this.filterInvoicesPaymentsStep) query = 'order_by=trademark%7Casc';
      else query = `${this.filterInvoicesPaymentsStep}%26order_by=trademark%7Casc`;
      if (!page) query += '%26page=1';
      else query += page;
      if (!perPage) query += '%26perPage=10';
      else query += perPage;
      try {
        const { data, status } = await this.listInvoicesPaymentsAffiliate(query);
        if (status === 200) {
          this.ActionSetInvoicesPaymentsStep(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCustomPayments() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=FINANCE&path=/api/custom_payment_groups/');
        if (status === 200) {
          let value = {};

          value = await Promise.all(
            data.map(async (item) => ({
              ...item,
              affiliateInGroup: await this.collectAffiliateInGroup(item.id)
            }))
          );

          this.ActionSetCustomPayments(value);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async collectAffiliateInGroup(groupId) {
      try {
        const { data, status } = await this.getCustomPaymentGroupAffiliateByGroup(groupId);
        if (status === 200) {
          return Promise.resolve().then(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    async getStatementsAdmin(dateFrom, dateTo, affiliateId, stateId, page, perPage) {
      if (!perPage) perPage = 10;
      if (!page) page = 1;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: 'finance',
          path: `/api/statements_admin/`,
          params: {
            data: {
              page: page,
              per_page: perPage,
              sort: 'trademark|asc',
              affiliateId: affiliateId,
              dataInicio: dateFrom,
              dataFim: dateTo,
              state_id: stateId
            }
          }
        });
        if (status === 200) {
          this.ActionSetInvoicesPayments(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getBillingsByAffiliate(dateFrom, dateTo, campaignId, affiliateId, stateId, page, perPage) {
      if (!perPage) perPage = 10;
      if (!page) page = 1;

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: 'finance',
          path: `/api/statements_admin/`,
          params: {
            data: {
              page: page,
              per_page: perPage,
              sort: 'trademark|asc',
              campaignId: campaignId,
              affiliateId: affiliateId,
              dataInicio: dateFrom,
              dataFim: dateTo,
              state_id: stateId
            }
          }
        });
        if (status === 200) {
          this.ActionSetBillingsByAffiliate(data.data);
          this.ActionSetPagination(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getStatementsAdminCsv(dateFrom, dateTo, affiliateId, stateId) {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: 'finance',
          path: `/api/statements_admin/csv`,
          params: {
            data: {
              sort: 'trademark|asc',
              affiliateId: affiliateId,
              dataInicio: dateFrom,
              dataFim: dateTo,
              state_id: stateId
            }
          }
        });
        if (status === 200) {
          return data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getAffiliatesPerGroup(groups) {
      this.onLoading(true);
      try {
        for (var group in groups) {
          const { data, status } = await this.$http.get(
            '/redirect?app=FINANCE&path=/api/custom_payment_group_affiliate/get_by_group/' + group.id
          );
          if (status === 200) {
            this.ActionSetCustomPaymentGroups(data);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  computed: {
    ...mapGetters('Financial', [
      'dialogFinancial',
      'dialogFinancialPI',
      'dialogEditPI',
      'pis',
      'filterPis',
      'infoPIs',
      'infoEditPIs',
      'billings',
      'filterBillings',
      'advertiserInvoices',
      'dialogCreateAdvertiserInvoice',
      'affiliateBillings',
      'advertiserInvoiceUpdate',
      'schedulePayments',
      'filterSchedulePayments',
      'invoicesPayments',
      'billingsByAffiliate',
      'filterInvoicesPayments',
      'filterBillingsByAffiliate',
      'customPayments',
      'filterCustomPayments',
      'filterInvoicesPaymentsStep',
      'invoicesPaymentsStep'
    ]),
  }
};
